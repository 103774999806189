@import 'style';

.newsdetail {
	&-social {
		margin-top: 50px;

		@include media($ipad-land) {
			margin-top: 0;
		}

		&-link {
			display: inline-block;
			vertical-align: top;

			& + & {
				margin-left: 50px;

				@include media($desktop) {
					margin-left: 75px;
				}
			}
		}
	}
}
