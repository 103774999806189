@import 'style';

.text {
	&-text {
		@include media($ipad-land) {
			padding: 0 75px;
		}

		@include media($desktop) {
			padding: 0 145px;
		}
	}
}
