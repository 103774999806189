@import 'style';

.newscard {
	display: inline-flex;
	vertical-align: top;

	&-image {
		position: relative;
		flex: 0 0 auto;
		width: 150px;
		height: 150px;
		display: inline-block;
		vertical-align: top;
		background-color: $blue-midnight;

		&-icon {
			position: absolute;
			left: 0;
			bottom: 0;
			color: white;
			font-size: 22px;
			width: 60px;
			height: 60px;
			padding: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $turquoise;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 20px;

		&-icon {
			color: $blue-midnight;
			font-size: 20px;
			line-height: 1;
			margin-top: 25px;
			transition: all 0.25s ease-out;
		}
	}

	&:hover .newscard {
		&-content-icon {
			transform: translate(5px, -5px);
		}
	}
}
