@import 'style';

.events {
	&-social {
		margin-top: 50px;

		@include media($ipad-land) {
			margin-top: 0;
		}
	}

	&-content {
		&-title {
			margin-bottom: 35px;
		}

		&-empty + &-title,
		&-items + &-title {
			margin-top: 75px;

			@include media($desktop) {
				margin-top: 125px;
			}
		}

		&-loadmore {
			margin-top: 50px;
		}
	}
}

.eventitem {
	color: $blue-midnight;

	& + & {
		margin-top: 30px;
	}

	&-titlerow {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-icon {
			flex: 0 0 auto;
			font-size: 20px;
			line-height: 1;
			margin-left: 25px;
			transition: all 0.25s ease-out;
		}

		&:hover .eventitem-titlerow-icon {
			transform: translate(5px, -5px);
		}
	}

	&-text {
		margin-top: 5px;
	}
}
