@import 'style';

.team {
	&-content {
		display: block;
	}
}

.category {
	& + & {
		margin-top: 90px;
	}

	&-title + &-items {
		margin-top: 55px;
	}

	&-items {
		margin-top: -70px;

		@include media($desktop) {
			padding-left: 145px;
		}
	}
}

.item {
	display: block;
	margin-top: 70px;
	max-width: 320px;

	@include media($tablet) {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding: 0 45px;
		border-left: 1px solid $border;
	}

	@include media($desktop) {
		width: calc(100% / 3);
	}

	&-image {
		overflow: hidden;
		background-color: $turquoise;
		height: 0;
		width: 100%;
		padding-bottom: 100%;
		margin-bottom: 25px;
	}

	&-description {
		font-size: 16px;
		line-height: 24px;
		margin-top: 5px;
	}

	&-email {
		color: $blue-midnight;
		margin-top: 25px;
	}
}
