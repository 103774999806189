@import 'style';

.contactpersons {
	&-title + &-text {
		margin-top: 25px;
	}

	&-text {
		max-width: 445px;

		@include media($desktop) {
			max-width: calc((445 / 1140) * 100%);
		}
	}

	&-link {
		margin-top: 35px;
	}

	&-items {
		margin-top: 35px;

		@include media($desktop) {
			padding-left: 335px;
		}

		&-item {
			padding: 15px 0;
			border-bottom: 1px solid $border;

			@include media($ipad-port) {
				display: flex;
				align-items: center;
			}

			&-link {
				max-width: 190px;
				font-weight: 700;
				color: $blue-midnight;
			}

			&-column {
				&:nth-child(1) {
					flex: 0 0 190px;
				}

				&:nth-child(2) {
					flex: 0 0 190px;
				}

				&:nth-child(3) {
					flex: 1 1 320px;
				}

				& + & {
					margin-top: 5px;

					@include media($ipad-port) {
						margin-top: 0;
						margin-left: 25px;
					}
				}

				& + &:nth-child(3) {
					@include media($ipad-port) {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
