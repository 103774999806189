@import 'style';

.entryoverview {
	margin: 75px 0;

	@include media($desktop) {
		border-bottom: 1px solid #cbcbcb;
	}

	&-items {
		margin-top: -50px;

		@include media($tablet) {
			display: flex;
			flex-wrap: wrap;
		}

		&-item {
			margin-top: 50px;
			border-left: 1px solid $border;

			.mod-compact & {
				border-left: none;

				@include media($desktop) {
					border-left: 1px solid $border;
				}
			}

			@include media($tablet) {
				display: inline-block;
				vertical-align: top;
				width: 50%;
			}

			@include media($desktop) {
				width: 25%;

				&:first-child {
					border-left: none;
				}
			}
		}
	}

	&.mod-compact {
		border-bottom: none;
	}
}
