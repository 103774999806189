@import 'style';

.hero {
	overflow: hidden;
	padding-top: 35px;
	margin-bottom: 35px;
	min-height: calc(260px / 2);

	@include media($ipad-port) {
		min-height: 195px;
	}

	@include media($desktop) {
		min-height: 260px;
	}

	&-titlerow {
		@include media($ipad-land) {
			display: flex;
			justify-content: space-between;
		}

		&-title {
			max-width: 735px;

			&.mod-image {
				max-width: 640px;
				margin-right: 45px;
			}
		}

		&-children {
			display: block;
		}
	}

	&-content {
		max-width: 640px;
		margin: 55px 0 auto auto;
	}

	&-row {
		display: flex;
		flex-direction: column-reverse;
		margin-top: 40px;

		@include media($ipad-land) {
			flex-direction: row;
			align-items: flex-end;
		}

		&-text {
			margin-top: 25px;
			margin-right: 45px;

			@include media($ipad-land) {
				margin-top: 0;
				flex: 0 0 445px;
			}
		}

		&-image {
			position: relative;
			max-width: 640px;

			@include media($ipad-land) {
				max-width: 100%;
			}

			&-img {
				position: relative;

				@include media($ipad-land) {
					width: calc(100% + 35px);
				}

				&:after {
					content: '';
					background: radial-gradient(
						at bottom left,
						rgba(82, 189, 194, 1) 0%,
						rgba(77, 182, 185, 0) 75%
					);
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}

.back {
	margin-bottom: 25px;
}
