@import 'style';

.image {
	position: relative;

	&.mod-background {
		width: 100%;
		height: 100%;
	}

	&.mod-portrait {
		width: 100%;
		height: 230px;

		@include media($ipad-port) {
			height: 460px;
		}
	}

	> div:first-child {
		vertical-align: top;
	}
}
