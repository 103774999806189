@import 'style';

.entrycard {
	padding: 40px;
	padding-top: 20px;

	@include media($tablet) {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&-content {
		flex: 1 1 auto;

		&-image {
			margin-bottom: 15px;
		}

		&-description {
			font-size: 16px;
			line-height: 24px;
			margin-top: 5px;
		}
	}

	&-link {
		margin-top: 25px;

		@include media($desktop) {
			margin-top: 95px;
		}
	}
}
