@import 'style';

.researchdetail {
	&-intro {
		margin: 35px 0;

		&-row {
			@include media($ipad-port) {
				display: flex;
				justify-content: space-between;
			}
		}

		&-text + &-image {
			margin-top: 50px;

			@include media($ipad-port) {
				margin-top: 0;
				margin-left: 85px;
			}
		}

		&-text {
			font-size: 36px;
			line-height: 40px;

			@include media($desktop) {
				font-size: 40px;
				line-height: 44px;
				padding-left: 145px;
			}
		}

		&-image {
			width: 100%;
			max-width: 375px;
			flex: 0 0 auto;
		}
	}
}
