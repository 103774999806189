@import 'style';

.imagelinks {
	overflow: hidden;
	padding-top: 35px;

	&-title {
		margin-bottom: 50px;
	}

	&-row {
		@include media($ipad-land) {
			display: flex;
			align-items: flex-end;
		}
	}

	&-image {
		flex: 1 1 auto;

		&-img {
			position: relative;

			@include media($ipad-land) {
				left: -35px;
				width: calc(100% + 35px) !important;
			}

			&:after {
				content: '';
				background: radial-gradient(
					at bottom left,
					rgba(82, 189, 194, 1) 0%,
					rgba(77, 182, 185, 0) 50%
				);
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&-content {
		@include media($ipad-land) {
			flex: 0 0 auto;
			width: 100%;
			max-width: 375px;
		}
	}
}

.content {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 35px 0;
	border-bottom: 1px solid $border;

	@include media($ipad-land) {
		padding-top: 0;
		padding-left: 35px;
		padding-bottom: 50px;
	}

	@include media($desktop) {
		padding-left: 75px;
	}

	&-text + &-image {
		margin-top: 40px;
	}

	&-text + &-links,
	&-image + &-links {
		margin-top: 50px;

		@include media($ipad-land) {
			margin-top: 75px;
		}
	}

	&-image {
		max-width: 375px;
	}

	&-links {
		max-width: 290px;

		&-link {
			& + & {
				margin-top: 50px;
			}
		}
	}
}

.items {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	&-item {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 35px 0;
		border-bottom: 1px solid $border;

		@include media($ipad-land) {
			padding-left: 35px;
			border-left: 1px solid $border;
		}

		@include media($desktop) {
			padding-left: 75px;
		}

		&-text + &-links {
			margin-top: 50px;

			@include media($ipad-land) {
				margin-top: 75px;
			}
		}

		&-links {
			&-link {
				& + & {
					margin-top: 25px;
				}
			}
		}
	}
}
