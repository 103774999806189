@import 'style';

.herohome {
	color: white;
	overflow: hidden;
	position: relative;
	background-color: #0f0f0f;
	padding-top: 125px;
	padding-bottom: 275px;

	@include media($ipad-land) {
		padding-bottom: 330px;
	}

	&-wrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;

		&.mod-video {
			position: absolute;
			top: 0;
			left: 0;
		}

		&-video {
			position: absolute;
			bottom: -10%;
			left: 50%;
			width: auto;
			height: auto;
			min-width: 100%;
			min-height: 100%;
			background-size: cover;
			transform: translateX(-50%);
		}
	}

	&-content {
		position: relative;
		z-index: 1;
		margin: auto 0 auto auto;
		max-width: calc(800px + 250px);

		@include media($ipad-land) {
			padding: 0 125px;
			text-align: right;
		}

		&-title + &-text {
			margin-top: 50px;

			@include media($desktop) {
				margin-left: 275px;
			}
		}

		&-title {
			font-weight: 400;
			text-align: left;

			strong {
				color: $turquoise;
				font-weight: 400;
			}
		}

		&-text {
			font-size: 16px;
			line-height: 22px;
			font-weight: 400;
			max-width: 420px;
			text-align: left;
		}
	}
}
