@import 'style';

.partners {
	position: relative;

	&-title + &-items {
		margin-top: 45px;

		@include media($ipad-land) {
			margin-top: 65px;
		}
	}

	&-items {
		border-top: 1px solid $border;
		border-left: 1px solid $border;

		&-item {
			width: 50%;
			height: 120px;
			padding: 20px;
			display: inline-flex;
			vertical-align: top;
			align-items: center;
			justify-content: center;
			border-right: 1px solid $border;
			border-bottom: 1px solid $border;

			@include media($ipad-port) {
				width: calc(100% / 3);
			}

			@include media($ipad-land) {
				width: 25%;
				height: 165px;
			}

			&.mod-link {
				align-items: flex-end;
				justify-content: flex-start;
			}

			&-link {
				width: 100%;
			}
		}
	}
}

.item {
	position: relative;
	display: block;
	width: 100px;

	&:hover:not([href]) {
		cursor: inherit;
	}
}
