@import 'style';
@import './Title.module.scss';

$color: $blue-midnight;

.wysiwyg {
	position: relative;
	word-break: break-word;

	* + * {
		margin-top: 20px;
	}

	h2,
	h3,
	h4,
	h5 {
		color: $blue-midnight;

		& + p {
			margin-top: 15px;
		}
	}

	h2 {
		@include heading2;
	}

	h3 {
		@include heading3;
	}

	h4 {
		@include heading4;
	}

	h5 {
		@include heading5;
	}

	b,
	strong {
		font-weight: 700;
	}

	a {
		color: $color;
		display: inline;
		border-bottom: 1px solid currentColor;
		transition: border 0.25s ease-out;

		&:hover {
			border-color: transparent;
		}
	}

	ol,
	ul {
		margin-left: 20px;

		li + li {
			margin-top: 5px;
		}
	}

	ul {
		list-style: none;

		li {
			position: relative;

			&:before {
				content: '•';
				color: $color;
				position: absolute;
				left: -20px;
			}
		}
	}

	blockquote {
		padding-left: 30px;
		border-left: 3px solid $color;
	}

	table {
		float: none;
		max-width: 100%;
		border-spacing: 0;
		border-collapse: collapse;

		& + * {
			margin-top: 50px;
		}

		thead tr {
			color: white;
			font-weight: 500;
			background-color: $color;

			th,
			td {
				border-color: lighten($color, 5%);
			}
		}

		tbody td {
			background-color: white;
		}

		th,
		td {
			padding: 15px;
			min-width: 100px;
			border: 1px solid #d6d9dd;

			@include media($ipad-port) {
				padding: 15px 30px;
			}
		}
	}

	img {
		height: auto;
		display: block;
		max-width: 320px;
	}
}
