@import 'style';

@mixin row {
	padding: 20px 0;
	border-bottom: 1px solid $border;

	@include media($ipad-land) {
		display: flex;
	}
}

.toolbox {
	&-head {
		@include row;
	}

	&-empty {
		margin-top: 50px;
	}
}

.filters {
	align-items: center;
	margin-bottom: 25px;
	width: 100%;

	@include media($desktop) {
		margin-left: -20px;
		padding-left: 145px;
	}

	&-top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	&-selected {
		position: relative;
	}

	&-item {
		min-width: 215px;
		display: inline-block;
		vertical-align: middle;
		transition: color 0.25s ease-out;

		&.is-selected {
			color: $blue-midnight;
		}

		& + & {
			margin-left: 30px;
		}
	}

	&-clear {
		display: inline-block;
		vertical-align: middle;
		margin-left: 30px;
	}
}

.column {
	flex: 0 0 125px;
	word-break: break-word;

	@include media($ipad-land) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.mod-small {
		font-size: 14px;
		line-height: 18px;
	}

	&.mod-minwidth {
		flex: 0 0 175px;
	}

	&.mod-maxwidth {
		flex: 0 1 445px;
	}

	& + & {
		margin-top: 15px;

		@include media($ipad-land) {
			margin-top: 0;
			margin-left: 45px;
		}
	}
}

.item {
	@include row;

	&-content {
		&-title {
			font-weight: 700;
		}

		&-title + &-text {
			margin-top: 15px;
		}
	}
}

.pagination {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	margin-top: 45px;

	&-item {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 35px;
		height: 35px;

		&.is-active {
			color: white;
			background-color: $blue-midnight;
		}
	}
}

.tag {
	color: white;
	background-color: $blue-midnight;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-weight: 500;
	margin: 5px 0 0 10px;
	padding: 5px 15px 5px 12px;
	border-radius: 50px;
	border: 1px solid $blue-midnight;
	line-height: 1.33;
	vertical-align: top;
	transition: all 0.25s ease-out;
	cursor: pointer;

	&:hover {
		.tag-content {
			transform: translateX(0);
		}
		.tag-icon {
			opacity: 1;
		}
	}

	&-content {
		display: inline-block;
		transition: transform 0.15s ease-out;

		@include media($desktop) {
			transform: translateX(10px);
		}
	}

	&-icon {
		font-size: 10px;
		margin-left: 10px;
		margin-right: -3px;
		display: inline-block;
		vertical-align: middle;
		color: currentColor;
		opacity: 1;

		@include media($desktop) {
			opacity: 0;
		}
	}
}
