@import 'style';

.list {
	overflow: hidden;

	&-title {
		padding-top: 35px;
	}

	&-text {
		max-width: 445px;
		margin: 55px 0 auto auto;
	}

	&-items {
		margin-top: -50px;

		@include media($ipad-port) {
			margin-left: -85px;
		}

		@include media($ipad-land) {
			padding-left: 75px;
		}

		@include media($desktop) {
			padding-left: 145px;
		}
	}

	&-link {
		margin-top: 65px;
		text-align: right;
	}

	&-title + &-items,
	&-text + &-items {
		margin-top: 80px;
	}
}

.item {
	display: block;
	margin-top: 50px;

	@include media($ipad-port) {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding-left: 85px;
	}

	&.mod-three_quarter {
		&:nth-child(odd) {
			@include media($ipad-port) {
				width: calc(100% - 385px);
			}

			@include media($desktop) {
				padding-left: 195px;
			}
		}

		&:nth-child(even) {
			@include media($ipad-port) {
				width: 385px;
			}
		}
	}

	&.mod-image {
		width: 100% !important;

		@include media($desktop) {
			padding-left: 85px !important;
		}
	}

	&-content {
		&-title {
			margin-bottom: 10px;
			padding-bottom: 10px;
			text-transform: uppercase;
			border-bottom: 1px solid #cbcbcb;
		}
	}

	&.mod-image .item {
		&-image,
		&-content {
			@include media($ipad-port) {
				display: inline-block;
				vertical-align: top;
				width: calc(50% - 42.5px);
			}
		}

		&-image {
			position: relative;
			margin-bottom: 25px;

			@include media($ipad-port) {
				margin-bottom: 0;
				margin-right: 85px;
			}

			&:after {
				content: '';
				background: radial-gradient(
					at bottom left,
					rgba(82, 189, 194, 1) 0%,
					rgba(77, 182, 185, 0) 50%
				);
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.mod-three_quarter.mod-image .item {
		@include media($ipad-port) {
			&-image {
				width: calc(100% - 385px);
			}

			&-content {
				width: 300px;
			}
		}
	}
}

.domains {
	display: block;
	margin-top: 50px;

	&-description {
		font-weight: bold;

		@include media($ipad-port) {
			margin-left: 0;
		}

		@include media($ipad-land) {
			padding-left: 75px;
		}

		@include media($desktop) {
			padding-left: 145px;
		}
	}
}
