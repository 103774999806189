@import 'style';

.home {
	position: relative;
	&:before {
		content: '';
		background: #08010f;
		width: 100%;
		height: 50px;
		top: -50px;
		left: 0;
		position: absolute;
	}
}

.carousel {
	overflow: hidden;
	position: relative;
	margin-top: -210px;
	margin-bottom: 75px;

	@include media($desktop) {
		margin-bottom: 125px;
	}

	&-slides {
		margin-left: -20px;

		&-slide {
			height: 270px;
			min-width: 100%;
			max-width: min-content;
			padding-left: 20px;

			&.mod-news_item {
				min-width: calc(270px + 20px);
			}

			@include media($tablet) {
				min-width: calc(480px + 20px);
			}
		}
	}

	&-nav {
		display: block;
		margin-top: 20px;
		text-align: right;

		@include media($ipad-port) {
			margin-top: 0;
		}

		&-prev + &-next {
			margin-left: 20px;
		}

		&-prev,
		&-next {
			width: 60px;
			height: 60px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background-color: $turquoise;

			&-icon {
				color: white;
				font-size: 22px;
				transition: transform 0.25s ease-out;
			}

			@include media($ipad-port) {
				position: absolute;
				z-index: 1;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&-prev {
			left: 0;

			&:hover .carousel-nav-prev-icon {
				@include media($desktop) {
					transform: translateX(-5px);
				}
			}
		}

		&-next {
			right: 0;

			&:hover .carousel-nav-next-icon {
				@include media($desktop) {
					transform: translateX(5px);
				}
			}
		}
	}
}

.card {
	overflow: hidden;
	position: relative;
	height: 100%;
	display: flex;
	align-items: flex-end;
	background-color: $blue-midnight;

	&-image,
	&-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&-image {
		backface-visibility: hidden;
		transition: transform 0.25s ease-out;
	}

	&-overlay {
		background: linear-gradient(
			to bottom,
			rgba(55, 51, 125, 1) 0%,
			rgba(0, 0, 0, 0.5) 100%
		);
		transition: opacity 0.25s ease-out;
	}

	&-diagonal {
		position: absolute;
		top: 20px;
		right: 20px;
		color: white;
		font-size: 22px;
		transition: transform 0.25s ease-out;
	}

	&-icon {
		position: relative;
		color: white;
		font-size: 22px;
		width: 60px;
		height: 60px;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $turquoise;
	}

	&-content {
		position: relative;
		padding: 10px 20px;
		color: white;

		&-date {
			margin-bottom: 5px;
		}

		&-title {
			font-size: 24px;
			line-height: 29px;
			font-weight: 400;
		}
	}

	&:hover .card {
		@include media($desktop) {
			&-diagonal {
				transform: translate(5px, -5px);
			}

			&-image {
				transform: scale(1.05);
			}

			&-overlay {
				opacity: 0;
			}
		}
	}
}
