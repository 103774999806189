@import 'style';

.news {
	&-social {
		margin-top: 50px;

		@include media($ipad-land) {
			margin-top: 0;
		}
	}

	&-items {
		&-item {
			& + & {
				margin-top: 30px;
			}
		}
	}
}
