@import 'style';

.contactform {
	width: 100%;
	max-width: 670px;
	margin: 0 auto;

	&-field {
		display: block;

		&-label {
			font-weight: 600;
			padding-bottom: 5px;
		}

		&-input,
		&-textarea {
			width: 100%;
			padding: 7px 10px;
			background-color: white;
			border-bottom: 2px solid $border;
			transition: border 0.25s ease-out;

			&:focus,
			&:hover {
				border-color: $blue-midnight;
			}
		}

		&-textarea {
			min-height: 125px;
		}

		& + & {
			margin-top: 15px;
		}

		&:global(.mod-checkbox) {
			line-height: 0;
			cursor: pointer;
			position: relative;

			input {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				height: 20px;
				width: 20px;

				&:checked ~ .contactform-field-input {
					&:after {
						opacity: 1;
						transform: scale(1);
					}
				}
			}

			.contactform-field {
				&-input,
				&-label {
					display: inline-block;
					vertical-align: middle;
				}

				&-input {
					position: relative;
					width: 20px;
					height: 20px;
					padding: 0;
					border: none;
					background-color: white;

					&:after {
						content: '✔';
						color: $blue-midnight;
						font-size: 20px;
						line-height: 1;
						position: absolute;
						top: 1px;
						left: 2px;
						opacity: 0;
						transform: scale(0);
						transition: all 0.25s ease-out;
					}
				}

				&-label {
					padding: 0;
					line-height: 1.2;
					margin-left: 15px;
				}
			}
		}
	}

	&-submit {
		margin-top: 50px;
	}
}
