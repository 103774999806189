@import 'style';

$color: $blue-midnight;

.linkstyled {
	line-height: 1;

	&-link {
		cursor: pointer;
		display: inline-flex;

		&.mod-aligncenter {
			align-items: center;
		}

		&.mod-minwidth {
			width: 175px;
			justify-content: space-between;
		}

		&.mod-fullwidth {
			display: flex;
			justify-content: space-between;
		}

		&:hover .linkstyled-link {
			&-text-label {
				color: $color;
				border-color: $color;
			}

			&-icon {
				&.mod-arrow_diagonal {
					transform: translate(5px, -5px);
				}

				&.mod-arrow_left {
					transform: translateX(-5px);
				}
			}
		}

		&-text,
		&-icon {
			& + & {
				margin-left: 15px;
			}
		}

		&-text {
			font-size: 16px;
			line-height: 1.3;
			max-width: 175px;
			text-align: left;

			.mod-small & {
				font-size: 14px;
				font-weight: 500;
			}

			&-label {
				display: inline;
				white-space: pre-line;
				border-bottom: 1px solid transparent;
				transition: all 0.25s ease-out;
			}
		}

		&-icon {
			flex: 0 0 auto;
			color: $color;
			font-size: 20px;
			line-height: 1;
			transition: all 0.25s ease-out;

			.mod-small & {
				font-size: 10px;
			}

			.mod-large & {
				font-size: 36px;
			}

			&.mod-arrow_left {
				font-size: 10px;
			}
		}
	}
}
