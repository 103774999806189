@import 'style';

.imagecta {
	overflow: hidden;
	padding-top: 35px;

	&-title {
		max-width: 360px;
		margin-bottom: 50px;
	}

	&-row {
		position: relative;
	}

	&-image {
		position: relative;

		&:after {
			content: '';
			background: radial-gradient(
				at top left,
				rgba(82, 189, 194, 1) 0%,
				rgba(77, 182, 185, 0) 50%
			);
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.content {
	background-color: $background;
	padding-top: 45px;

	@include media($ipad-port) {
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;
		width: 100%;
		max-width: 410px;

		padding-left: 45px;
	}

	@include media($desktop) {
		padding-left: 75px;
	}

	&-text + &-cta {
		margin-top: 25px;
	}
}
