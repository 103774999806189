@import 'style';

.video {
	position: relative;
	overflow: hidden;
	max-width: 100%;
	height: 0;
	padding-bottom: calc(100% / (3 / 2));
	background-color: $blue-midnight;

	@include media($tablet) {
		padding-bottom: calc(100% / (16 / 9));
	}

	&-play {
		position: relative;
		background-color: white;
		width: 75px;
		height: 75px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.25s ease-out;

		@include media($ipad-land) {
			width: 110px;
			height: 110px;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 100%;
		}

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 0;
			margin-left: 5px;
			border-left: 20px solid $turquoise;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			transition: border 0.25s ease-out;

			@include media($ipad-land) {
				border-left: 30px solid $turquoise;
				border-top: 18px solid transparent;
				border-bottom: 18px solid transparent;
			}
		}

		&:hover {
			@include media($desktop) {
				background-color: $turquoise;

				&:before {
					animation: pulse-animation 1s infinite;
				}

				&:after {
					border-left-color: white;
				}
			}
		}
	}
}

@keyframes pulse-animation {
	0% {
		opacity: 0.5;
		box-shadow: 0 0 0 0px $turquoise;
	}
	100% {
		opacity: 0;
		box-shadow: 0 0 0 25px $turquoise;
	}
}
