@import 'style';

.columns {
	margin: 75px 0;

	@include media($desktop) {
		border-bottom: 1px solid #cbcbcb;
	}

	&-items {
		@include media($tablet) {
			display: flex;
			flex-wrap: wrap;
			margin-top: -50px;
		}

		&-item {
			padding: 40px 0;
			border-bottom: 1px solid $border;

			@include media($tablet) {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				padding: 40px;
				margin-top: 50px;
				border-bottom: none;
				border-left: 1px solid $border;
			}

			@include media($desktop) {
				width: 25%;
				min-height: 375px;

				&:first-child {
					border-left: none;
				}
			}

			&.mod-image {
				padding: 0;
			}

			&-image {
				position: relative;

				@include media($desktop) {
					left: -35px;
					width: calc(100% + 35px);
				}
			}
		}
	}
}

.item {
	@include media($tablet) {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&-content {
		flex: 1 1 auto;

		&-text {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&-link {
		margin-top: 25px;

		@include media($desktop) {
			margin-top: 95px;
		}
	}
}
