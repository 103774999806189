@import 'style';

.cases {
	&-title {
		max-width: 600px;
	}

	&-text {
		max-width: 445px;
		margin-top: 15px;

		@include media($desktop) {
			max-width: calc((445 / 1140) * 100%);
		}
	}

	&-items {
		margin-top: 25px;

		@include media($desktop) {
			padding-left: 335px;
		}
	}
}

.item {
	padding: 35px 0;

	& + & {
		border-top: 1px solid #cbcbcb;
	}

	&-titlerow {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-icon {
			position: relative;
			width: 20px;
			height: 20px;
			flex: 0 0 auto;
			margin-left: 15px;

			@include media($ipad-land) {
				width: 40px;
				height: 40px;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				backface-visibility: hidden;
				background-color: $blue-midnight;
				transition: all 0.25s ease-out;
			}

			&:before {
				top: 0;
				left: 50%;
				width: 4px;
				height: 100%;
				margin-left: -2px;
			}

			&:after {
				top: 50%;
				left: 0;
				width: 100%;
				height: 4px;
				margin-top: -2px;
			}

			&.is-open {
				&:before {
					opacity: 0;
					transform: rotate(90deg);
				}
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	&-content {
		padding-top: 25px;

		&-media {
			margin-bottom: 15px;
		}

		&-text {
			display: block;
		}

		&-links {
			margin-top: -25px;
			margin-left: -75px;

			&-link {
				display: inline-block;
				vertical-align: top;
				margin-top: 75px;
				padding-left: 75px;
			}
		}
	}
}
