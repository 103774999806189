@import 'style';

.imagetext {
	position: relative;

	&-row {
		@include media($ipad-port) {
			display: flex;
			flex-direction: row-reverse;

			&.mod-left {
				flex-direction: row;
			}
		}
	}

	&-side {
		width: 100%;
		max-width: 290px;
		position: relative;

		@include media($ipad-port) {
			.mod-left & {
				order: 1;
			}
		}

		&-image + &-button {
			margin-top: 25px;
		}
	}

	&-content {
		align-self: center;
		margin-top: 25px;

		@include media($ipad-port) {
			flex: 1 1 auto;
			margin-top: 0;
			margin-right: 85px;

			.mod-left & {
				order: 2;
				margin-right: 0;
				margin-left: 85px;
			}
		}

		@include media($desktop) {
			padding-left: 145px;

			.mod-left & {
				padding-left: 0;
				padding-right: 145px;
			}
		}

		&-text {
			display: block;
		}
	}
}
