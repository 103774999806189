@import 'style';

.listdomains {
	overflow: hidden;

	&-title {
		padding-top: 35px;
	}

	&-text {
		max-width: 445px;
		margin: 55px 0 auto auto;
	}

	&-items {
		margin-top: -50px;

		@include media($ipad-port) {
			margin-left: -85px;
		}

		@include media($ipad-land) {
			padding-left: 75px;
		}

		@include media($desktop) {
			padding-left: 145px;
		}
	}

	&-title + &-items,
	&-text + &-items {
		margin-top: 80px;
	}

	&-link {
		margin-top: 65px;
		text-align: right;
	}
}

.listitem {
	display: block;
	margin-top: 50px;

	@include media($ipad-port) {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding-left: 85px;
	}

	&-title {
		margin-bottom: 10px;
		padding-bottom: 10px;
		text-transform: uppercase;
		border-bottom: 1px solid #cbcbcb;
	}
}

.domains {
	margin-top: 75px;

	@include media($ipad-land) {
		padding-left: 75px;
	}

	@include media($desktop) {
		padding-left: 145px;
	}

	&-items {
		@include media($tablet) {
			margin-left: -75px;
		}
	}
}

.domainitem {
	margin-top: 25px;

	@include media($tablet) {
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding-left: 75px;
	}

	@include media($ipad-land) {
		width: 25%;
	}

	&-image {
		margin-bottom: 15px;
	}

	&-title {
		padding-bottom: 10px;
		border-bottom: 1px solid $border;
	}
}
