@import 'style';

.dropdown {
	position: relative;
	transition: background 0.25s ease-out;

	&:hover,
	&:global(.is-open) {
		color: white;
		background-color: $blue-midnight;

		&.mod-select {
			color: $gray-dark;

			.dropdown-control {
				border-color: $blue-midnight;
			}
		}
	}

	:global(.Dropdown-placeholder),
	:global(.Dropdown-arrow-wrapper) {
		display: inline-block;
		vertical-align: middle;
	}

	:global(.Dropdown-arrow-wrapper) {
		line-height: 0;
		margin-left: 10px;
	}

	&-control {
		cursor: pointer;
		font-weight: 600;
		padding: 7px 20px;
		border: none;

		.mod-select & {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 400;
			padding: 7px 10px;
			background-color: white;
			border-bottom: 2px solid $border;
			transition: border 0.25s ease-out;
		}
	}

	&-menu {
		position: absolute;
		z-index: 1;
		top: 100%;
		left: 0;
		width: 100%;

		&-option {
			cursor: pointer;
			color: white;
			line-height: 1;
			font-weight: 500;
			display: block;
			padding: 10px 15px;
			background-color: $hover;
			transition: all 0.25s ease-out;

			&:hover,
			&:global(.is-selected) {
				color: white;
				background-color: $blue-midnight;
			}
		}
	}

	&-arrow {
		font-size: 12px;
		display: inline-block;
		vertical-align: top;
		transition: transform 0.25s ease-out;

		&.mod-open {
			transform: rotate(-180deg);
		}
	}
}
