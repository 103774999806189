@import 'style';

@mixin row {
	padding: 20px 0;
	border-bottom: 1px solid $border;

	@include media($ipad-land) {
		display: flex;
		justify-content: space-between;
	}
}

.library {
	&-head {
		@include row;
	}

	&-empty {
		margin-top: 50px;
	}
}

.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 60px;

	&-item {
		width: 32px;
		height: 32px;
		font-weight: 300;
		color: $gray-dark;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: all 0.25s ease-out;

		&:hover {
			background-color: rgba($blue-midnight, 0.1);
		}

		&.is-active {
			color: white;
			background-color: $blue-midnight;
		}
	}
}

.filters {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;

	@include media($desktop) {
		margin-left: -20px;
		padding-left: 145px;
	}

	&-item {
		min-width: 215px;
		display: inline-block;
		vertical-align: middle;
		transition: color 0.25s ease-out;

		&.is-selected {
			color: $blue-midnight;
		}

		& + & {
			margin-left: 30px;
		}
	}

	&-clear {
		display: inline-block;
		vertical-align: middle;
		margin-left: 30px;
	}
}

.column {
	flex: 0 0 125px;
	word-break: break-word;

	@include media($ipad-land) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&.mod-small {
		font-size: 14px;
		line-height: 18px;
	}

	&.mod-minwidth {
		flex: 0 0 175px;
	}

	&.mod-maxwidth {
		flex: 1 1 445px;
	}

	& + & {
		margin-top: 15px;

		@include media($ipad-land) {
			margin-top: 0;
			margin-left: 45px;
		}
	}
}

.item {
	@include row;

	&-content {
		&-title {
			font-weight: 700;
		}

		&-title + &-text {
			margin-top: 15px;
		}
	}

	&-link {
		margin-top: 15px;
	}
}
